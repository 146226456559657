import React from "react";
import { FaBootstrap } from "react-icons/fa";
import { FaHtml5 } from "react-icons/fa";
import { IoLogoCss3 } from "react-icons/io5";
import { FaReact } from "react-icons/fa";
import { FaNode } from "react-icons/fa6";
import { SiExpress } from "react-icons/si";
import { SiGooglefonts } from "react-icons/si";
import { FaPython } from "react-icons/fa6";
import { SiMongodb } from "react-icons/si";
import { FaWordpress } from "react-icons/fa";
import { FaPhp } from "react-icons/fa";


const FeatureItem = ({ text }) => (
  <li className="mv-feature flex items-center space-x-2">
    <div className="icon text-green-500">
      <i className="feather-check" />
    </div>
    <div className="text text-sm font-medium text-gray-700">{text}</div>
  </li>
);

export default function Sliding() {
  const features = [
    <FaBootstrap className="w-14 h-14 mx-2" />,
    
    <IoLogoCss3 className="w-14 h-14 mx-2" />,
    <FaHtml5 className="w-14 h-14 mx-2" />,
    <FaReact className="w-14 h-14 mx-2"  />,
    <FaNode className="w-14 h-14 mx-2"  />,
    <SiExpress className="w-14 h-14 mx-2"  />,
    <SiMongodb className="w-14 h-14 mx-2"  />,
    <SiGooglefonts className="w-14 h-14 mx-2"  />,

    <FaPython className="w-14 h-14 mx-2"  />,
    <FaWordpress className="w-14 h-14 mx-2"  />,
    
    <FaPhp className="w-14 h-14 mx-2"  />,
    
  ];

  return (
    <div className="move-feature-area overflow-hidden py-8 bg-gray-100 my-3">
      <div className="wrapper">
        <ul className="mv-feature-list flex animate-slide whitespace-nowrap">
          {features.map((feature, index) => (
            <FeatureItem key={index} text={feature} />
          ))}
        </ul>
      </div>
    </div>
  );
}
