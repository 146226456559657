import React, { useState, useEffect } from "react";
import { motion } from "framer-motion";
import Logo from "../assets/logos/logo-w-name.png";

export default function Intro() {
  // Define the text for the typewriter effect
  const typewriterText =
    "We are a group of experienced individuals with utmost potential, believing in providing you with the best solutions.We offer client-friendly deals with zero compromise on our quality of work. Our objective is to boost your business by putting our endless efforts and services to your work.";

  // State to hold the substring of text being displayed
  const [displayText, setDisplayText] = useState("");

  // Effect to control the typewriter animation
  useEffect(() => {
    let currentIndex = 0;
    const interval = setInterval(() => {
      setDisplayText(typewriterText.substring(0, currentIndex));
      currentIndex++;

      if (currentIndex > typewriterText.length) {
        clearInterval(interval);
      }
    }, 50);

    return () => clearInterval(interval);
  }, [typewriterText]);

  return (
    <>
      <div className="mt-4 pb-10 flex flex-col md:flex-row text-black font-lato justify-between items-center mx-6 md:mx-16 lg:mx-24">
        <div className="w-full md:w-2/5 text-center md:text-left mb-8 md:mb-0">
          <h1 className="text-3xl md:text-4xl lg:text-5xl font-bold mb-6 md:mb-10">
            Synnovara : <span className="text-gray-500">A Team of Innovators</span>
          </h1>
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ duration: 1 }}
          >
            <p className="text-lg md:text-xl">{displayText}</p>
          </motion.div>
        </div>
        <div>
          <img src={Logo} alt="Synnovara Logo" className="w-64 h-64 md:w-80 md:h-80 lg:w-96 lg:h-96" />
        </div>
      </div>
    </>
  );
}
