import React from "react";
import T1 from "../assets/T1.png"
import T2 from "../assets/T2.jpeg"
import T3 from "../assets/T3.jpeg"
export default function Testimonial() {
    return (
        <div>
            <section className="py-12 bg-gradient-to-r from-lime-400 via-lime-200 to-lime-500 sm:py-16 lg:py-20">
                <div className="px-4 mx-auto max-w-7xl sm:px-6 lg:px-8">
                    <div className="flex flex-col items-center">
                        <div className="text-center">
                            <h2 className="mt-4 text-3xl font-bold text-gray-900 sm:text-4xl xl:text-5xl font-pj">
                                What clients say about us
                            </h2>
                        </div>

                        <div className="mt-8 text-center md:mt-16 md:order-3">
                            <a
                                href="/Contact"
                                title=""
                                className="pb-2 text-base font-bold leading-7 text-gray-900 transition-all duration-200 border-b-2 border-gray-900 hover:border-gray-600 font-pj focus:outline-none focus:ring-1 focus:ring-gray-900 focus:ring-offset-2 hover:text-gray-600"
                            >
                                Contact Us
                            </a>
                        </div>

                        <div className="relative mt-10 md:mt-24 md:order-2">


                            
                            <div
                                className="absolute -inset-x-1 inset-y-16 md:-inset-x-2 md:-inset-y-6"
                                style={{
                                    background: "linear-gradient(90deg, #44ff9a -0.55%, #44b0ff 22.86%, #8b44ff 48.36%, #ff6644 73.33%, #ebff70 99.34%)",
                                    filter: "blur(20px)",
                                    opacity: 0.3,
                                    borderRadius: "1.5rem",
                                }}
                            ></div>

                            <div className="relative grid max-w-lg grid-cols-1 gap-6 mx-auto md:max-w-none lg:gap-10 md:grid-cols-3">
                            <div className="flex flex-col overflow-hidden shadow-xl animate-dance rounded-lg border-4 border-transparent bg-clip-border bg-gradient-to-r from-pink-500 via-red-500 to-yellow-500">
                                    <div className="flex flex-col justify-between flex-1 p-6 bg-white lg:py-8 lg:px-7">
                                        <div className="flex items-center mb-4">
                                            {[...Array(5)].map((_, index) => (
                                                <svg
                                                    key={index}
                                                    className="w-5 h-5 text-[#FDB241]"
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    viewBox="0 0 20 20"
                                                    fill="currentColor"
                                                >
                                                    <path
                                                        d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z"
                                                    />
                                                </svg>
                                            ))}
                                        </div>
                                        <div className="mb-4">
                                            <p className="text-gray-700 text-base">
                                                "This is a fantastic service! The attention to detail and customer support were beyond my expectations. Highly recommended for anyone looking for quality and reliability."
                                            </p>
                                        </div>
                                        <div className="flex items-center">
                                            <img
                                                className="w-12 h-12 rounded-full object-cover"
                                                src= {T1}
                                                alt="Client photo"
                                            />
                                            <div className="ml-4">
                                                <p className="text-gray-900 font-semibold">Rohail Imran</p> {/* Replace with client's name */}
                                                <p className="text-gray-600 text-sm"></p> {/* Replace with client's title and company */}
                                            </div>
                                        </div>
                                    </div>
                                </div>


                                <div className="flex flex-col overflow-hidden shadow-xl animate-dance rounded-lg border-4 border-transparent bg-clip-border bg-gradient-to-r from-pink-500 via-red-500 to-yellow-500">
                                    <div className="flex flex-col justify-between flex-1 p-6 bg-white lg:py-8 lg:px-7">
                                        <div className="flex items-center mb-4">
                                            {[...Array(5)].map((_, index) => (
                                                <svg
                                                    key={index}
                                                    className="w-5 h-5 text-[#FDB241]"
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    viewBox="0 0 20 20"
                                                    fill="currentColor"
                                                >
                                                    <path
                                                        d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z"
                                                    />
                                                </svg>
                                            ))}
                                        </div>
                                        <div className="mb-4">
                                            <p className="text-gray-700 text-base">
                                               "Highly recommended for anyone seeking top-notch service and innovative solutions."
                                            </p>
                                        </div>
                                        <div className="flex items-center">
                                            <img
                                                className="w-12 h-12 rounded-full object-cover"
                                                src= {T2}
                                                alt="Client photo"
                                            />
                                            <div className="ml-4">
                                                <p className="text-gray-900 font-semibold">Noraiz Hussain</p> {/* Replace with client's name */}
                                                <p className="text-gray-600 text-sm"></p> {/* Replace with client's title and company */}
                                            </div>
                                        </div>
                                    </div>
                                </div>




                                <div className="flex flex-col overflow-hidden shadow-xl animate-dance rounded-lg border-4 border-transparent bg-clip-border bg-gradient-to-r from-pink-500 via-red-500 to-yellow-500">
                                    <div className="flex flex-col justify-between flex-1 p-6 bg-white lg:py-8 lg:px-7">
                                        <div className="flex items-center mb-4">
                                            {[...Array(5)].map((_, index) => (
                                                <svg
                                                    key={index}
                                                    className="w-5 h-5 text-[#FDB241]"
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    viewBox="0 0 20 20"
                                                    fill="currentColor"
                                                >
                                                    <path
                                                        d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z"
                                                    />
                                                </svg>
                                            ))}
                                        </div>
                                        <div className="mb-4">
                                            <p className="text-gray-700 text-base">
                                            "Working with Synnovara was a game-changer for our business. Their innovative approach and thorough understanding of our needs led to outstanding results."
                                            </p>
                                        </div>
                                        <div className="flex items-center">
                                            <img
                                                className="w-12 h-12 rounded-full object-cover"
                                                src= {T3}
                                                alt="Client photo"
                                            />
                                            <div className="ml-4">
                                                <p className="text-gray-900 font-semibold">Ahmed Adil</p> {/* Replace with client's name */}
                                                <p className="text-gray-600 text-sm"></p> {/* Replace with client's title and company */}
                                            </div>
                                        </div>
                                    </div>
                                </div>
 

                                
                            </div>
                        </div>

                        <div className="mt-8 text-center md:mt-16 md:order-1">
                            <a
                                href="https://www.facebook.com/profile.php?id=61565543078791&sk=reviews"
                                target="_blank"
                                title=""
                                className="pb-2 text-base font-bold leading-7 text-gray-900 transition-all duration-200 border-b-2 border-gray-900 hover:border-gray-600 font-pj focus:outline-none focus:ring-1 focus:ring-gray-900 focus:ring-offset-2 hover:text-gray-600"
                            >
                                Check all reviews
                            </a>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    );
}
