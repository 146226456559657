import "../App.css";
import Baner from "../components/Baner";
import Navbar from "../components/Navbar";
import Intro from "../components/Intro";
import OurServices from "../components/OurServices";

import CardGallery from "../components/CardGallery";
import Testimonial from "../components/Testimonial";
import Sliding from "../components/Sliding";

function Home() {
  return (
    <div className="App bg-white">
      <Baner />
      <div className="home-content">
        <div className='bg-gradient-to-r from-lime-400 via-lime-200 to-lime-500 rounded-b-[2rem]'>
          <Navbar/>
          <Intro />
          </div>
          <OurServices/>
          <CardGallery/> 
          <Sliding/>    
          <Testimonial/>
        </div>
        
      </div>
    );
  }
  
  export default Home;
  