import React from "react";
import Team from "../assets/Team/Synnovara.jpg";
import gif1 from "../assets/html.gif"


export default function About_intro() {
  return (
    <>
    <style>
        {`
          @keyframes fadeIn {
            from {
              opacity: 0;
            }
            to {
              opacity: 1;
            }
          }

          .fade-in {
            animation: fadeIn 2s ease-in-out;
          }
        `}
        {`
          @keyframes slideInFromLeft {
            from {
              transform: translateX(-100%);
              opacity: 0;
            }
            to {
              transform: translateX(0);
              opacity: 1;
            }
          }

          .slide-in-left {
            animation: slideInFromLeft 2s ease-out;
          }
        `}
      </style>
    
      <div className="relative mt-4 pb-10 flex flex-col md:flex-row text-black font-lato justify-between items-center mx-6 md:mx-16 lg:mx-24">
        {/* Background image section */}
        <h1 className="text-2xl md:text-4xl lg:text-5xl font-bold slide-in-left mb-2">
                Synnovara : <span className="text-gray-600">A Team of Highly Skilled Professionals</span>
              </h1>
              
              <div
        className="w-full h-[400px] md:h-[500px] lg:h-[800px] bg-cover bg-center bg-no-repeat fade-in"
        style={{ backgroundImage: `url(${Team})`, backgroundSize: 'contain' }}
      >
         
        </div>
      </div>

      
    </>
  );
}
