import React, { useState } from "react";
import { motion } from "framer-motion";
import "daisyui/dist/full.css"; // Ensure Daisy UI styles are imported

const WhiteProjectSection = ({ title, description, badges, imageSrc, imageAlt, images }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [currentIndex, setCurrentIndex] = useState(0);

  const fadeIn = {
    hidden: { opacity: 0, y: 20 },
    visible: { opacity: 1, y: 0, transition: { duration: 0.8 } }
  };

  const imageVariant = {
    hidden: { opacity: 0, scale: 0.8 },
    visible: { opacity: 1, scale: 1, transition: { duration: 0.6, ease: "easeInOut" } }
  };

  const openModal = (index) => {
    setCurrentIndex(index);
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  const handleNext = () => {
    setCurrentIndex((prevIndex) => (prevIndex + 1) % images.length);
  };

  const handlePrev = () => {
    setCurrentIndex((prevIndex) => (prevIndex - 1 + images.length) % images.length);
  };

  const handleClickOutside = (e) => {
    if (e.target === e.currentTarget) {
      closeModal();
    }
  };

  return (
    <>
      <motion.div
        initial="hidden"
        whileInView="visible"
        viewport={{ once: true }}
        className="flex flex-col-reverse md:flex-row rounded-xl my-6 md:my-10 bg-white mx-auto w-[90%] md:w-[80%] h-auto bg-gradient-to-r from-white to-lime-100 shadow-lg"
      >
        <motion.div variants={imageVariant} className="w-full md:w-[50%] flex justify-center items-center">
          <img
            className="rounded-xl w-[95%] md:w-[90%] h-auto shadow-md hover:scale-105 transition-transform duration-300 cursor-pointer"
            src={imageSrc}
            alt={imageAlt}
            onClick={() => openModal(0)}
          />
        </motion.div>

        <motion.div variants={fadeIn} className="flex flex-col justify-between p-6 md:p-10 w-full md:w-[50%]">
          <div>
            <h1 className="text-2xl md:text-3xl font-bold mb-4 text-gray-800">{title}</h1>
            <p className="text-base md:text-lg text-gray-700 leading-relaxed">
              {description}
            </p>
          </div>
          <div className="flex flex-wrap gap-2 mt-4">
            {badges.map((badge, index) => (
              <span key={index} className="bg-gray-800 text-white text-sm font-medium px-3 py-1 rounded-full">{badge}</span>
            ))}
          </div>
        </motion.div>
      </motion.div>

      {/* Modal */}
      {isModalOpen && (
        <div
          className="fixed inset-0 bg-black bg-opacity-60 flex justify-center items-center z-50"
          onClick={handleClickOutside}
        >
          <div className="relative bg-white rounded-lg w-[90%] md:w-[70%] lg:w-[50%]">
            <button
              className="absolute top-2 right-2 text-gray-500 hover:text-gray-700 text-2xl font-bold"
              onClick={closeModal}
            >
              &times;
            </button>
            <div className="relative">
              <button
                className="absolute left-2 top-1/2 transform -translate-y-1/2 bg-gray-800 text-white rounded-full p-2 shadow-lg"
                onClick={handlePrev}
              >
                &lt;
              </button>
              <button
                className="absolute right-2 top-1/2 transform -translate-y-1/2 bg-gray-800 text-white rounded-full p-2 shadow-lg"
                onClick={handleNext}
              >
                &gt;
              </button>
              <div className="flex overflow-hidden">
                <img
                  src={images[currentIndex]}
                  alt={`Slide ${currentIndex + 1}`}
                  className="w-full h-auto object-cover rounded-lg"
                />
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default WhiteProjectSection;
