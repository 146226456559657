// import React, { Children } from "react";
import React from 'react';
import { useLocation } from 'react-router-dom'; // Import useLocation hook
import Navbar from './components/Navbar';
import Footer from './components/Footer';

export default function Layout({ children }) {
  const location = useLocation(); // Get the current location

  // Check if the current route is not the home page
  const showNavbar = location.pathname !== '/';

  return (
    <div>
      {showNavbar && <Navbar />} {/* Conditionally render Navbar */}
      {children}
      <Footer />
    </div>
  );
}