import React from "react";
import { motion } from "framer-motion";
import GreenProjectSection from "../components/GreenProjectSection";
import WhiteProjectSection from "../components/WhiteProjectSection";

// Import images for the projects

import cakeoclock1 from "../assets/projects/cake o clock/C1.png";
import cakeoclock2 from "../assets/projects/cake o clock/C2.png";
import cakeoclock3 from "../assets/projects/cake o clock/C3.png";

import primordial from "../assets/projects/primordial.png";
import primordial1 from "../assets/projects/primordial-enc.png";
import primordial2 from "../assets/projects/primordial-dec.png";

import blocksmith from "../assets/projects/blocksmith.png";
import blocksmith1 from "../assets/projects/blocksmith-dashboard.png";
import blocksmith2 from "../assets/projects/blocksmith-mining.png";

import EstateElite from "../assets/projects/estate-elite.png";
import EstateElite1 from "../assets/projects/estate-elite-find.png";
import EstateElite2 from "../assets/projects/estate-elite-list.png";
import EstateElite3 from "../assets/projects/estate-elite-about.png";
import EstateElite4 from "../assets/projects/estate-elite-admin.png";

import ArchitectPortfolio from "../assets/projects/architects portfolio site/Screenshot 2024-08-27 at 2.24.09 PM.png";
import ArchitectPortfolio1 from "../assets/projects/architects portfolio site/Screenshot 2024-08-27 at 2.24.24 PM.png";
import ArchitectPortfolio2 from "../assets/projects/architects portfolio site/Screenshot 2024-08-27 at 2.24.53 PM.png";

import SheenPosh from "../assets/projects/shein pos/Screenshot 2024-09-09 at 1.53.39 PM.png";
import SheenPosh1 from "../assets/projects/shein pos/Screenshot 2024-09-09 at 1.54.15 PM.png";
import SheenPosh2 from "../assets/projects/shein pos/Screenshot 2024-09-09 at 1.57.33 PM.png";

export default function Project() {
  return (
    <>
      <motion.h1
        initial={{ opacity: 0, y: -50 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.8 }}
        className="text-black font-lato font-bold text-3xl md:text-4xl text-center my-6 md:my-10"
      >
        Our Projects
      </motion.h1>

      {/* Cake 'o Clock Project Section */}
      <GreenProjectSection
        title="Cake 'o Clock"
        description="Cake o' clock, an online bake shop, got a bloom in their sales just in the recent past by hand us over their website development project.Our exceptional team used technologies like REACT. JS, TAILWIND and JAVASCRIPT to make things do wonders for our client."
        badges={["React.js", "Tailwind", "JavaScript"]}
        imageSrc={cakeoclock1}
        imageAlt="Cake 'o Clock"
        images={[cakeoclock1, cakeoclock2 ,cakeoclock3]}
      />

      {/* Blocksmith Project Section */}
      <WhiteProjectSection
        title="BlockSmith"
        description="Blocksmith is one of our top notch works. It’s a platform used in block chain mining and transaction validation. Our well equipped developers used REACT for fromt end development and NODE. JS for backend. We used python to handle blockchain logic which made it a wonderful user-loving tool to comprehend blockchain technology."
        badges={["React.js", "Node.js", "MongoDB", "Python"]}
        imageSrc={blocksmith}
        imageAlt="BlockSmith"
        images={[blocksmith, blocksmith1, blocksmith2]}
      />

      {/* Primordial Project Section */}
      <GreenProjectSection
        title="Primordial"
        description="Primordial increased security by using multiple values for key generation that ultimately enhances RSA encryption. It ensures increased strength encryption by integrating Microsoft crypt-generation API. Our developers built the platform using REACT and FLASK to offer advanced protection for sensitive data, it combines with multi-value encryption for robust security. "
        badges={["React.js", "Flask", "MongoDB"]}
        imageSrc={primordial}
        imageAlt="Primordial"
        images={[primordial, primordial1, primordial2]}
      />

      {/* Estate Elite Project Section */}
      <WhiteProjectSection
        title="Estate Elite"
        description="Estate elite, a real estate platform, is designed for realestate management. It includes marvelous panoramic visuals of properties. Users view and control listings complete with a protected panel for verification.The platform is based on REAVT. JS, NODE. JS and MY SQL , so buyers, sellers and renters have an easy interference and healthy experience in the purchasing process of properties. "
        badges={["React.js", "Panolens", "Node.js", "MySQL"]}
        imageSrc={EstateElite}
        imageAlt="Estate Elite"
        images={[EstateElite, EstateElite1, EstateElite2, EstateElite3, EstateElite4]}
      />

      {/* Duoscappers Project Section */}
      <GreenProjectSection
        title="Duoscappers"
        description="Duoscappers is an impressive digital portfolio site that our developers designed for an architecture firm, it showcases projects with high quality images and delicate description. The attractive design assures users a great experience. Built with HTML, CSS and JAVASCRIPT, it extracted the exact reults what our clients asked for"
        badges={["HTML", "CSS", "JavaScript"]}
        imageSrc={ArchitectPortfolio}
        imageAlt="Duoscappers"
        images={[ArchitectPortfolio, ArchitectPortfolio1, ArchitectPortfolio2]}
      />

      {/* SHEIN POS System Project Section */}
      <WhiteProjectSection
        title="SHEIN POS System"
        description="SHEIN POS SYSTEM is one of our defining works. It equips retail operations with features like product management, invoicing and receipt issuances. All these features make our project supremely uuse-friendly. Our developers used REACT. JS, BOOTSTRAP 5,NODE.JS and MONGO DB. These technologies increased SHEIN POS SYSTEM efficiency to manage retail transactions. "
        badges={["React.js", "Bootstrap 5", "Node.js", "MongoDB"]}
        imageSrc={SheenPosh}
        imageAlt="SHEIN POS System"
        images={[SheenPosh, SheenPosh1, SheenPosh2]}
      />
    </>
  );
}
