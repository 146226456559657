import React from 'react';
import ServiceCard from './ServiceCard';
import { FaCode, FaDatabase, FaGlobe, FaWordpress } from 'react-icons/fa';

const Service = () => {
  const services = [
    {
      title: 'Frontend Development',
      description: 'Building visually appealing and responsive interfaces with HTML, CSS, JavaScript, and modern frameworks like React and Vue.',
      icon: <FaCode />,
    },
    {
      title: 'Backend Development',
      description: 'Creating robust server-side applications using Node.js, Express, Python, and other backend technologies.',
      icon: <FaDatabase />,
    },
    {
      title: 'Full Stack',
      description: 'Developing full-stack applications using MongoDB, Express, React, and Node.js for a complete end-to-end solution.',
      icon: <FaGlobe />,
    },
    {
      title: 'WordPress Development',
      description: 'Designing and customizing WordPress websites with themes, plugins, and custom development to suit your business needs.',
      icon: <FaWordpress />,
    },
  ];

  return (
    <section className="py-16 bg-gray-100">
      <div className="container mx-auto px-4">
        <h2 className="text-3xl font-bold text-center mb-12">Our Services</h2>
        <div className="grid gap-8 sm:grid-cols-2 lg:grid-cols-4">
          {services.map((service, index) => (
            <ServiceCard
              key={index}
              title={service.title}
              description={service.description}
              icon={service.icon}
            />
          ))}
        </div>
      </div>
    </section>
  );
};

export default Service;
