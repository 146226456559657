import React, { useState } from 'react';
import { FaMapMarkerAlt, FaPhoneAlt, FaClock } from 'react-icons/fa';
import { motion } from 'framer-motion';
import emailjs from 'emailjs-com';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

export default function ContactUs() {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    message: ''
  });

  const [errorMessage, setErrorMessage] = useState('');
  const [isSubmitDisabled, setIsSubmitDisabled] = useState(false);

  // Regex for allowed characters: a-zA-Z0-9 ., @ ""
  const allowedCharacters = /^[a-zA-Z0-9.,@'"\s]*$/;

  const handleChange = (e) => {
    const { name, value } = e.target;
    let valid = true;
  
    if (name === "name" && !/^[a-zA-Z\s'-]+$/.test(value)) {
      setErrorMessage("Name can only include letters, spaces, dashes, and apostrophes.");
      valid = false;
    } else if (name === "email" && !/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/.test(value)) {
      setErrorMessage("Please enter a valid email address.");
      valid = false;
    } else if (!allowedCharacters.test(value)) {
      setErrorMessage('No special characters are allowed except . , "" @ and \'');
      valid = false;
    } else {
      setErrorMessage('');
    }
  
    setIsSubmitDisabled(!valid);
  
    setFormData({
      ...formData,
      [name]: value
    });
  };
  

  const handleSubmit = (e) => {
    e.preventDefault();

    // Only proceed if the form is valid
    if (isSubmitDisabled || errorMessage) return;

    emailjs.send(
      "service_xn9dwri",  
      "template_r18xpbd",
      {
        from_name: formData.name,
        message: formData.message + "\n\nSent from: " + formData.email,
        from_email: formData.email,
      },
      "u_FtY6fyUnhMVz1TD"
    )
    .then((result) => {
      toast.success('Message sent successfully!', {
        position: "bottom-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        style: {
          backgroundColor: "#333",
          color: "#6B8E23",
          fontSize: "16px",
          borderRadius: "8px",
        }
      });
    }, (error) => {
      toast.error('Failed to send the message, please try again.', {
        position: "bottom-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        style: {
          backgroundColor: "#ff3333",
          color: "#fff",
          fontSize: "16px",
          borderRadius: "8px",
        }
      });
    });

    setFormData({
      name: '',
      email: '',
      message: '',
    });
  };

  return (
    <section className="bg-white font-lato" id="contact">
      <ToastContainer />
      <div className="mx-auto max-w-7xl px-4 py-8 sm:px-6 lg:px-8 lg:py-10">
        {/* Header */}
        <motion.div
          initial={{ opacity: 0, y: -50 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.6 }}
          className="mb-4"
        >
          <div className="mb-6 max-w-3xl text-center sm:text-center md:mx-auto md:mb-12">
            <p className="text-base font-semibold uppercase tracking-wide text-black">
              Contact
            </p>
            <h2 className="font-heading mb-4 font-bold tracking-tight text-[#6B8E23] text-3xl sm:text-5xl">
              Get in Touch
            </h2>
          </div>
        </motion.div>

        <div className="flex items-stretch justify-center">
          <div className="grid md:grid-cols-2">
            {/* Contact Information */}
            <motion.div
              initial={{ opacity: 0, x: -50 }}
              animate={{ opacity: 1, x: 0 }}
              transition={{ duration: 0.6 }}
              className="h-full pr-6"
            >
              <p className="mt-3 mb-12 text-lg text-gray-600 ">
                We’d love to hear from you! Whether you have a question about our projects, need assistance, or just want to give feedback, our team is here to help.
              </p>
              <ul className="mb-6 md:mb-0">
                <li className="flex items-center mb-6">
                  <div className="flex h-10 w-10 items-center justify-center rounded bg-black text-gray-50">
                    <FaPhoneAlt className="text-lime-500 text-xl" />
                  </div>
                  <div className="ml-4 lg:w-[250px]">
                    <h3 className="mb-2 text-lg font-medium leading-6 text-gray-900">Contact</h3>
                    <p className="text-gray-600 text-left ml-5 mb-1">Mobile: +92 322 8405321</p>
                    <p className="text-gray-600 text-left ml-5">Email: synnovara@gmail.com</p>
                  </div>
                </li>
                <li className="flex items-center mb-6">
                  <div className="flex h-10 w-10 items-center justify-center rounded bg-black text-gray-50">
                    <FaClock className="text-lime-500 text-xl" />
                  </div>
                  <div className="ml-4 lg:w-[250px]">
                    <h3 className="mb-2 text-lg font-medium leading-6 text-gray-900">Working hours</h3>
                    <p className="text-gray-600 text-left ml-5 mb-1">Mon - Fri: 08:00 - 17:00</p>
                    <p className="text-gray-600 text-left ml-5">Sat & Sun: 08:00 - 12:00</p>
                  </div>
                </li>
              </ul>
            </motion.div>

            {/* Contact Form */}
            <motion.div
              initial={{ opacity: 0, x: 50 }}
              animate={{ opacity: 1, x: 0 }}
              transition={{ duration: 0.6 }}
              className="card h-fit max-w-6xl p-5 md:p-12 bg-[#f1f5f9] rounded-lg shadow-lg"
              id="form"
            >
              <h2 className="mb-2 text-2xl font-bold text-[#6B8E23]">Ready to Get Started?</h2>
              <p className='mt-2 mb-4 text-base text-gray-600'>If you are looking to develop a website from scratch or want to enhance your existing web pages or want custom features added to your website, drop us a line.</p>
              <form id="contactForm" onSubmit={handleSubmit}>
                <div className="mb-6">
                  <label htmlFor="name" className="sr-only">Name</label>
                  <input
                    type="text"
                    id="name"
                    autoComplete="name"
                    placeholder="Your name"
                    className="mb-2 w-full rounded-md border border-gray-300 py-2 pl-4 shadow-md"
                    name="name"
                    value={formData.name}
                    onChange={handleChange}
                  />
                </div>
                <div className="mb-6">
                  <label htmlFor="email" className="sr-only">Email</label>
                  <input
                    type="email"
                    id="email"
                    autoComplete="email"
                    placeholder="Your email"
                    className="mb-2 w-full rounded-md border border-gray-300 py-2 pl-4 shadow-md"
                    name="email"
                    value={formData.email}
                    onChange={handleChange}
                  />
                </div>
                <div className="mb-6">
                  <label htmlFor="message" className="sr-only">Message</label>
                  <textarea
                    id="message"
                    placeholder="Your message"
                    className="mb-2 w-full rounded-md border border-gray-300 py-2 pl-4 shadow-md"
                    name="message"
                    rows="5"
                    value={formData.message}
                    onChange={handleChange}
                  ></textarea>
                </div>

                {/* Error Message */}
                {errorMessage && (
                  <div className="text-red-500 mb-4">
                    {errorMessage}
                  </div>
                )}

                <div className="text-center">
                  <button type="submit" className="w-full bg-black text-[#6B8E23] px-6 py-3 font-bold rounded-md" disabled={isSubmitDisabled}>
                    Send Message
                  </button>
                </div>
              </form>
            </motion.div>
          </div>
        </div>
      </div>
    </section>
  );
}
