import React from "react";

export default function Cards(props){

  const truncateText = (text, maxLength) => {
    if (text.length > maxLength) {
      return text.substring(0, maxLength) + "...";
    }
    return text;
  };

    return(
      <div className="w-full sm:w-[90%] md:w-[45%] lg:w-[30%] mx-auto bg-gradient-to-r from-lime-400 via-lime-200 to-lime-500 rounded-xl shadow-lg overflow-hidden transform hover:scale-105 transition-transform duration-300 mb-5">
      <img
        className="w-full h-48 object-cover"
        src={props.img}
        alt={props.title}
      />
      <div className="p-6">
        <h3 className="text-xl font-bold text-gray-900 text-left ">{props.title}</h3>
        <p className="mt-4 text-black text-left line-clamp-3">
          {truncateText(props.description, 160)}
        </p>
        <div className="mt-6 flex flex-col sm:flex-row justify-between items-center">
         
        </div>
      </div>
    </div>

    );

}