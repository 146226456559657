import React from 'react'
import About_intro from '../components/About_intro'
import Team_intro from '../components/Team_intro'
const About = () => {
  return (
    <div>
      <About_intro/>
      <Team_intro/>
    </div>
  )
}

export default About
