import React from "react";
import Cards from "./Cards";
import p1 from "../assets/projects/p1.png"
import blocksmith from "../assets/projects/blocksmith.png";
import primordial from "../assets/projects/primordial.png";
export default function CardGallery( ){
    return ( 

    <>
    <h1 className="text-black font-lato font-bold text-4xl text-center ">Featured Projects</h1>
        <div className="flex flex-wrap justify-center py-5 px-5 ">
                <Cards
                img={p1}
                title="Cake 'o Clock"
                description="Cake 'o Clock is a website built for an online bakery, Cake'o Clock. It is a full stack project built using React, Node.js, Express.js and MongoDB. The website allows users to view the bakery's products, add them to cart and place an order. The website also has an admin panel where the bakery owner can add, update and delete products." 
                />

                 <Cards
                img={blocksmith}
                title="BlockSmith"
                description="BlockSmith is an educational blockchain simulation platform that lets users experience blockchain mining and transaction validation. Using React for the frontend and Node.js for the backend, it simulates Proof of Work (PoW) with a custom coin, Bux. Python handles the blockchain logic, making it a hands-on learning tool for understanding blockchain technology."
                />
                <Cards
                img={primordial}
                title="Primordial"
                description="Primordial enhances RSA encryption by using multiple values for key generation, increasing security. It integrates with the Microsoft CryptGen API for high-strength encryption. The platform, built with React and Flask, offers advanced protection for sensitive data, combining multi-value encryption with industry-standard techniques for robust security."
                />
            </div>

            <div className="mb-4">
               {/* text to show all projects */}
               <a href="/Project">
                <h1 className="text-black font-lato font-bold text-xl text-center hover:underline transition-all">View All Projects</h1>
               </a>
            </div>
        


    </>           
    );
}