import React, { useState } from 'react';
import logo from '../assets/logos/logo.png';
import { motion, AnimatePresence } from 'framer-motion';

export default function Navbar(props) {
  const [dropdownOpen, setDropdownOpen] = useState(false);

  const toggleDropdown = () => {
    setDropdownOpen(!dropdownOpen);
  };

  return (
    <nav >
      <div className="container mx-auto flex justify-between items-center">
        <div className="text-black text-lg font-semibold">
          <a href="/">
          <div className="flex flex-row justify-center items-center text-2xl">
            <a href="/"><img src={logo} alt="Logo" className="h-32 w-32" />
            
            </a>
            <div className='mb-4'>
              <a href="/"><p className="font-lato font-bold text-4xl">Synnovara</p></a>
              <p className="font-lato font-bold text-[12px] text-gray-500">Synnergy-Innovation-Aura</p>
            </div>
          </div>
          </a>
        </div>
        <div className="hidden md:flex space-x-4 mb-8">
          <a href="/" className="text-black font-semibold text-lg transition-all hover:text-gray-200">Home</a>
          <a href="/Project" className="text-black font-semibold text-lg transition-all hover:text-gray-200">Projects</a>
          {/* <a href="/Services" className="text-black font-semibold text-lg transition-all hover:text-gray-200">Services</a> */}
          <a href="/Contact" className="text-black font-semibold text-lg transition-all hover:text-gray-200">Contact</a>
          <a href="/About" className="text-black font-semibold text-lg transition-all hover:text-gray-200">About</a>
        </div>
        <div className="relative md:hidden">
          <button onClick={toggleDropdown} className="text-black mb-6 mr-2">
            <svg className="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M4 6h16M4 12h16M4 18h16"></path>
            </svg>
          </button>
          <AnimatePresence>
            {dropdownOpen && (
              <motion.div
                initial={{ opacity: 0, y: -10 }}
                animate={{ opacity: 1, y: 0 }}
                exit={{ opacity: 0, y: -10 }}
                transition={{ duration: 0.3, ease: "easeInOut" }}
                className="absolute right-0 mt-2 mr-2 w-48 bg-white border rounded-lg shadow-lg z-10"
              >
                <a href="/" className="block px-4 py-2 text-black hover:bg-gray-100">Home</a>
                <a href="/Project" className="block px-4 py-2 text-black hover:bg-gray-100">Projects</a>
                {/* <a href="/Services" className="block px-4 py-2 text-black hover:bg-gray-100">Services</a> */}
                <a href="/Contact" className="block px-4 py-2 text-black hover:bg-gray-100">Contact</a>
                <a href="/About" className="block px-4 py-2 text-black hover:bg-gray-100">About</a>
              </motion.div>
            )}
          </AnimatePresence>
        </div>
      </div>
    </nav>
  );
}
