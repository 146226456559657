import React from "react";
import { RiTwitterXFill } from "react-icons/ri";
import { IoLogoLinkedin } from "react-icons/io5";
import { IoLogoInstagram } from "react-icons/io";
import { FaFacebook } from "react-icons/fa";
export default function Footer(){
    return ( 
        <footer className="footer footer-center bg-white text-black rounded p-4 ">
     
      <nav className="p-10">
        <h1>

          <a href="https://linktr.ee/Synnovara"> Contact Us </a>
          
        </h1>
        <div className="grid grid-flow-col gap-4">
          <a href="https://www.linkedin.com/company/synnovara/posts/?feedView=all" target="_blank">
          <IoLogoLinkedin 
          className="w-10 h-10"
          />
          
          </a>
          {/* whatsapp*/}
          <a href="https://www.instagram.com/synnovara_solutions/" target="_blank">
          <IoLogoInstagram className="w-10 h-10"
          />
          
          </a>
          <a href="https://x.com/synnovara?mx=2" target="_blank">
          <RiTwitterXFill 
          className="w-10 h-10"
          />
            
          </a>

          <a href="https://www.facebook.com/profile.php?id=61565543078791&mibextid=ZbWKwL" target="_blank">
          <FaFacebook 
          className="w-10 h-10"
          />
          </a>
        </div>
      </nav>
      <aside className="flex justify-center">
        <p>Copyright © {new Date().getFullYear()} - All rights reserved by Synnovara</p>
      </aside>
    </footer>
    );
}

