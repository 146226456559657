import React, { useState, useEffect } from 'react';
import { motion } from 'framer-motion';
import Logo from '../assets/logos/logo.png';

const Baner = () => {
  const [isVisible, setIsVisible] = useState(true);

  useEffect(() => {
    const timer = setTimeout(() => {
      setIsVisible(false);
    }, 10000); // Adjust the duration to match your animation

    return () => clearTimeout(timer);
  }, []);

  return (
    isVisible && (
      <motion.div
        className="fixed top-0 left-0 w-full h-full z-50 flex justify-center items-center bg-gradient-to-r from-lime-400 via-lime-200 to-lime-500"
        initial={{ y: 0, opacity: 1 }}
        animate={{ y: '-100%', opacity: 0 }}
        transition={{ duration: 2, ease: 'easeInOut' }}
      >
        <div className="flex flex-col justify-center items-center w-[100vw] h-[100vh]">
          <img className="w-80 h-80" src={Logo} alt="Synnovara Logo" />
          <div className="absolute left-0 right-0 top-[54%] text-center">
            <h1 className="text-[4.5rem] font-bold font-lato">
              Synnovara
            </h1>
            <h2 className="text-2xl text-gray-500 font-medium">
              Synnergy-Innovation-Aura
            </h2>
          </div>
        </div>
      </motion.div>
    )
  );
};

export default Baner;
