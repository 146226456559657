import React from 'react'
import Ashir from '../assets/Team/aashir.png'
import abdullah from '../assets/Team/abdullah.png'
import ali from '../assets/Team/ali.png'
import umer from '../assets/Team/umer.png'
import sibtain from '../assets/Team/sibtain.png'
import saad from '../assets/Team/saad.png'
import { FaLinkedin } from "react-icons/fa";



const teamMembers = [
    
    {
      name: "Abdullah Wajid",
      position: "Founder",
      imageUrl: abdullah,
      linkedInUrl: "https://www.linkedin.com/in/abdullah-wajid-11876b274/",
      intro: "Founded with a passion for innovation, Synnovara was established to deliver cutting-edge software solutions that empower businesses to thrive in the digital era.",
    },

    {
      name:"Agha Muhammad Ali",
      position:"Co-Founder",
      imageUrl: ali,
      linkedInUrl: "https://www.linkedin.com/in/agha-muhammad-ali-khan-67bb65289/",
      intro: "As co-founder of Synnovara, I am driven by the vision to blend creativity and technology, delivering transformative software that pushes boundaries and fuels business growth.",
      

    },

    {
      name: "Syed Aashir Imran",
      position: "Frontend Developer",
      imageUrl: Ashir,
      linkedInUrl: "https://www.linkedin.com/in/syed-aashir-imran-2aa9801a4/",
      intro: "As a frontend developer at Synnovara, I craft seamless and intuitive user experiences that bring our clients' visions to life with precision and creativity.",
    },
    {
      name: "Umer Mehmood",
      position: "Backend Developer",
      imageUrl: umer,
      linkedInUrl: "https://www.linkedin.com/in/umer-mehmood-035844230/ ",
      intro: "As a backend developer at Synnovara, I build robust and scalable systems that ensure our applications run smoothly and efficiently behind the scenes.",
    },
    {
      name:"Sibtain Wali",
      position:"PR Head",
      imageUrl: sibtain,
      linkedInUrl: "https://www.linkedin.com/in/sibtain-wali-a7113b26a/",
      intro: "As the PR Head at Synnovara, I foster strong relationships and ensure our brand's story resonates with clients, partners, and the tech community.",

    },
    {
      name: "Saad Ahmed",
      position:"Content Writer",
      imageUrl: saad,
      linkedInUrl: "https://www.linkedin.com/in/saad-ahmed-7a",
      intro:"As a content writer at Synnovara, I create compelling and informative content that engages audiences and effectively communicates our brand's message.",

    }
  ];
const Team_intro = () => {
    return (

      
        <div className="bg-gradient-to-r from-lime-400 via-lime-200 to-lime-500 rounded-b-[2rem] py-12 px-4">
          <div className="max-w-7xl mx-auto text-center mb-12">
            <h1 className="text-4xl font-bold text-gray-800">Meet Our Team</h1>
            <p className="mt-4 text-gray-600">
              Our team of skilled professionals dedicated to building great digital experiences.
            </p>
          </div>
          <div className="grid gap-8 sm:grid-cols-2 md:grid-cols-3">
            {teamMembers.map((member, index) => (
              <div key={index} className="bg-white rounded-lg shadow-lg p-6 text-center">
                <img
                  className="w-32 h-32 mx-auto rounded-full mb-4"
                  src={member.imageUrl}
                  alt={member.name}
                />
                <h2 className="text-xl font-semibold text-gray-800">{member.name}</h2>
                <p className="text-gray-500">{member.position}</p>
                <p className="mt-4 text-gray-600">{member.intro}</p>
                <a
                  href={member.linkedInUrl}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="inline-block mt-4 text-blue-600 hover:text-blue-800"
                >
                  <FaLinkedin className="inline-block w-6 h-6" />
                </a>
              </div>
            ))}
          </div>
        </div>
      );
}

export default Team_intro
