import React from "react";
import WebDev1 from "../assets/services/web-dev-1.png";
import WordPressDev1 from "../assets/services/wordpress1.png";
import { FaCode, FaWordpress } from "react-icons/fa";

export default function OurServices() {
  return (
    <section className="py-16 text-black">
      <div className="  px-4 sm:px-6 lg:px-8 lg:w-full">
        {/* Heading */}
        <div className="flex flex-col">

        <h2 className="text-3xl font-bold text-center text-gray-900 sm:text-4xl">
          Our Services
        </h2>
        <p className="mt-4 max-w-2xl mx-auto text-center text-lg text-gray-800">
          We offer a wide range of web development services to help your business grow online.
        </p>
        </div>

        {/* Service 1: Web Development */}
        <div className="mt-12 lg:ml-[7rem] flex flex-col lg:flex-row items-center justify-between lg:space-x-8 lg:w-3/4">
          {/* Text Section */}
          <div className="lg:w-1/2 w-full text-left mb-8 lg:mb-0">
            <h3 className="text-3xl font-bold text-gray-900 mb-4 flex items-center">
              <FaCode className="text-lime-500 w-12 h-12 mr-2" />
              Web Development
            </h3>
            <p className="text-lg text-gray-700 mb-6">
            Our web developers are expert in providing you a wide range of web development services which will help you grow your business by leaps and bounds.Our professional developers are master in handling technologies like react, angular and Vue.jus with great ease that reflect in their quality work. We assure in providing a fast,smooth,secure and user-friendly website. 
            </p>
          </div>

          {/* Image Section */}
          <div className="lg:w-1/2 w-full">
            <img
              src={WebDev1}
              alt="Web Development Service"
              className="w-full h-auto rounded-lg "
            />
          </div>
        </div>

        {/* Service 2: WordPress Development */}
        <div className="mt-16 lg:ml-[18rem] flex flex-col lg:flex-row-reverse items-center justify-between lg:space-x-8 lg:w-3/4">
          {/* Text Section */}
          <div className="lg:w-1/2 w-full text-left mb-8 lg:mb-0">
            <h3 className="text-3xl font-bold text-gray-900 mb-4 flex items-center">
              <FaWordpress className="text-lime-500 w-12 h-12 mr-2" />
              WordPress Development
            </h3>
            <p className="text-lg text-gray-700 mb-6">
            Your online presence marks your brand to grow for which you need WordPress solutions.No worries, we are here helping you create a smooth,dynamic and customizable WordPress website that will make your brand to stand out.
            </p>
          </div>

          {/* Image Section */}
          <div className="lg:w-1/2 w-full">
            <img
              src={WordPressDev1}
              alt="WordPress Development Service"
              className="w-full h-auto rounded-lg "
            />
          </div>
        </div>
      </div>
    </section>
  );
}
